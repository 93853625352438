<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import type { GqlError } from "nuxt-graphql-client";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

defineProps<{ error: Error | GqlError }>();

const show = ref(true);

function close() {
  show.value = false;
}

setTimeout(() => {
  show.value = false;
}, 15000);
</script>
<template>
  <section v-if="show" class="error">
    <p>
      En feil har oppstått:
      <em>{{ !!error && "message" in error ? error?.message : error }}</em>
      <button class="error__close" @click="close">
        <KsIcon :icon="faXmark" />
      </button>
    </p>
  </section>
</template>

<style scoped lang="scss">
.error {
  width: 100vw;
  display: block;
  background-color: $brown-40;
  color: $pink-10;
  font-size: 1.4rem;
  min-height: 3rem;

  &__close {
    cursor: pointer;
    margin-right: 2rem;
    color: $pink-10;
    font-size: 1.4rem;
  }

  p {
    max-width: $max-width;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
